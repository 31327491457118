import React from "react"
import { SecondaryButton } from "../components/button"
import Card from "../components/card"
import Img from "../components/img"

type Value = {
  icon?: React.ElementType
  header: string
  description: string
  action?: string
  link?: string
  image: string
}

type ValuesListProps = {
  header?: string
  subheader?: string
  values: Array<Value>
}

export default function ValuesList(props: ValuesListProps) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-fea10362c98dcf74d601fab911a1aee3
  return (
    <Card>
      {props.header && (
        <p className="mt-2 text-gray-900 text-center text-4xl font-extrabold tracking-tight">
          {props.header}
        </p>
      )}
      {props.subheader && (
        <p className="mt-3 text-lg text-center text-gray-500">
          {props.subheader}
        </p>
      )}
      {props.values.map((value, index) => (
        <div className="mb-8" key={value.header}>
          <div
            className={`flex flex-col-reverse items-center ${
              index % 2 ? "md:flex-row-reverse" : "md:flex-row"
            }`}
          >
            <div className="w-full p-8 md:w-2/3 text-center md:text-left">
              {value.icon && (
                <div className="flex justify-center md:justify-start">
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-blue-600">
                    <value.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              )}
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {value.header}
                </h2>
                {value.description.split(/\n\n/g).map((description, i) => (
                  <p
                    key={`value-${index}-${i}`}
                    className="mt-4 text-lg text-gray-500"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                ))}
                {value.action && value.link && (
                  <div className="mt-6">
                    <SecondaryButton href={value.link} text={value.action} />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full mt-12 sm:mt-16 lg:mt-0 md:w-1/3">
              <div className="p-4 sm:px-6 lg:px-0 lg:m-0 max-w-full">
                <Img
                  className="w-full max-w-sm max-h-72 rounded-xl mx-auto"
                  src={value.image}
                  alt={value.header}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}
