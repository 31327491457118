// Profiles
export const Profiles = {
  kreg: "profile-kreg.jpeg",
  claire: "profile-claire.jpeg",
  emily: "profile-emily.png",
  sharon: "profile-sharon.jpeg",
  jen: "profile-jen.jpeg",
  shlomi: "profile-shlomi.jpeg",
  madhu: "profile-madhu.jpeg",
  kathleen: "profile-kathleen.jpeg",
  christine: "profile-christine.jpeg",
  samuel: "profile-samuel.jpeg",
  drew: "profile-drew.jpeg",
  catherine: "profile-catherine.jpeg",
  elizabeth: "profile-elizabeth.jpeg",
  ayinde: "profile-ayinde.jpeg",
  susanna: "profile-susanna.jpeg",
}

// Videos
export const Videos = {
  yupForSchools: {
    url: "https://player.vimeo.com/video/434145335",
    cover: "cover-yup-for-schools.jpeg",
  },
  yupOneMinIntro: {
    url: "https://player.vimeo.com/video/649647227",
    cover: "cover-yup-for-schools.jpeg",
  },
  yupForTeachers: {
    url: "https://player.vimeo.com/video/511218837",
    cover: "cover-yup-for-teachers.jpeg",
  },
  whatIsYup: {
    url: "https://player.vimeo.com/video/465916853",
    cover: "cover-what-is-yup.jpeg",
  },
  guidingQuestions: {
    url: "https://player.vimeo.com/video/358873974",
    cover: "cover-guiding-questions.jpeg",
  },
  expertTutors: {
    url: "https://player.vimeo.com/video/358873859",
    cover: "cover-expert-tutors.jpeg",
  },
  yupDemo: {
    url: "https://player.vimeo.com/video/641668961",
    cover: "cover-yup-demo.png",
  },
  yupDashboardDemo: {
    url: "https://player.vimeo.com/video/641708307",
    cover: "cover-yup-dashboard.png",
  },
  teacherWalkthrough: {
    url: "https://player.vimeo.com/video/528945409",
    cover: "cover-teacher-walkthrough.jpeg",
  },
  integratingYup: {
    url: "https://player.vimeo.com/video/585916811",
    cover: "cover-integrating-yup.jpeg",
  },
  webinarOne: {
    url: "https://player.vimeo.com/video/652938653",
    cover: "cover-webinar.jpeg",
  },
}
