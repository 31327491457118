import React from "react"

type CardProps = {
  children: React.ReactNode
  className?: string
}
export default function Card(props: CardProps) {
  return (
    <div className={`px-4 sm:px-8 max-w-7xl my-8 mx-auto ${props.className}`}>
      <div
        className={`bg-white rounded-lg shadow-lg p-4 sm:p-4 ring-1 ring-gray-900 ring-opacity-5 ${props.className}`}
      >
        {props.children}
      </div>
    </div>
  )
}
