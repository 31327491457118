import React from "react"
import Img from "./img"

const Content = {
  steps: [
    {
      image: "how-it-works-1.png",
      text: "Student submits math problem",
      description: `School students simply snap a photo of a problem they
        are working on and can connect with Yup tutors on any device`,
    },
    {
      image: "how-it-works-2.png",
      text: "Student connects to tutor in seconds",
      description: `Yup's Smart Matching technology connects them with
        an expert tutor instantly for a private tutoring session`,
    },
    {
      image: "how-it-works-3.png",
      text: "Student and tutor start solving",
      description: `Through our text-based platform, Yup tutors work
        with your students until they fully understand the concept,
        not just until they get the right answer.`,
    },
    {
      image: "how-it-works-4.png",
      text: "Session data on Yup Dashboard",
      description: `All schools have access to a dashboard showcasing student
        usage, progress metrics and session transcripts.`,
    },
  ],
}

type HowItWorksProps = {
  showDescription?: boolean
}

export default function HowItWorks(props: HowItWorksProps) {
  return (
    <div className="max-w-7xl mx-auto py-8 grid sm:grid-cols-2 lg:grid-cols-4 md:justify-evenly">
      {Content.steps.map((step, index) => (
        <div
          key={`how-it-works-step-${index}`}
          className="flex flex-col items-center my-8 md:mx-8 lg:my-0"
        >
          <Img
            src={step.image}
            alt={step.text}
            className="h-32 w-32 lg:h-48 lg:w-48 mb-4 max-w-full"
          />
          <p className="text-center text-xl lg:text-2xl font-bold">
            {step.text}
          </p>
          {props.showDescription && (
            <p className="text-center text-gray-400">{step.description}</p>
          )}
        </div>
      ))}
    </div>
  )
}
