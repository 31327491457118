import React from "react"
import { Profiles, Videos } from "../../data/assets"
import { Routes } from "../../data/routes"
import Card from "../../components/card"
import { ClosingCTA } from "../../components/CTA"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { SecondaryButton } from "../../components/button"
import PageHeader from "../../components/PageHeaderBlueH1"
import Img from "../../components/img"
import ValuesList from "../../components/ValuesList"
import { Carousel } from "react-responsive-carousel"
import { Disclosure } from "@headlessui/react"

import "react-responsive-carousel/lib/styles/carousel.min.css"

import {
  AcademicCapIcon,
  KeyIcon,
  LightningBoltIcon,
  ArrowsExpandIcon,
  UserGroupIcon,
  ThumbUpIcon,
  TrendingDownIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline"
import HowItWorks from "../../components/HowItWorks"

const Content = {
  pageName: "For Administrators - Online Math School Tutoring Programs",
  subheader: "Online Math Tutoring for Schools",
  header: `Yup is your Math Teacher Multiplier`,
  description: `We deliver cost-effective, 1:1 math tutoring that expands math teacher capacity,
    while accelerating student achievement at scale. Our core belief that ‘everything starts with the
    teacher’ drives our efforts to connect tutoring to the classroom and integrate it within
    the school day or curriculum.`,
  video: Videos.yupForSchools,
  headerCTALink: Routes.schools,
  headerCTAText: "Schedule Demo",

  // Quotes section
  quotes: [
    {
      text: `We see students use Yup more than 20 times, which demonstrates to
        me that they are using this as a critical resource. I feel confident that some
        students passed their math classes because they had Yup.`,
      profile: Profiles.catherine,
      name: "Catherine Mencher",
      job1: "Academic Affairs Director",
      job2: "College Track",
    },
    {
      text: `Yup has been a great resource for our students, especially in this time of
        distance-learning. The 24/7 access to a math tutor has helped reduce student
        frustration and ultimately helped them feel more successful in class...
        After a successful roll-out at our middle schools, we are happy to be
        extending our Yup services to our elementary school students.`,
      profile: Profiles.ayinde,
      name: "Ayindé Rudolph Ed.D.",
      job1: "Superintendent",
      job2: "Mountain View Whisman School District",
    },
  ],

  // Features section
  featuresHeader:
    "Achieve your goals while supporting your teachers and students",
  features: [
    {
      feature: "Accelerate Math Achievement",
      icon: AcademicCapIcon,
      text: `Yup's expert tutors and evidence-based Teaching Framework drive
        long-term learning and help students build positive relationships with math.`,
    },
    {
      feature: "Establish Equitable Access to Tutoring",
      icon: UserGroupIcon,
      text: `With Yup, each of your students, including those who don't typically have
        access to private tutoring, will benefit from personalized instruction.`,
    },
    {
      feature: "Expand Teacher Capacity",
      icon: ArrowsExpandIcon,
      text: `Yup extends teachers' reach inside and outside the classroom and allows them
        to apply their focus where it's needed most.`,
    },
  ],

  // How it helps
  howItHelpsHeader: "How Online Math Tutoring Services Help Administrators",
  howItHelps: [
    {
      header:
        "Challenges: Unfinished Learning, Achievement Gap, Teacher Shortage",
      description: `School leaders are working overtime to address
        <a href="${Routes.blog}accelerating-to-address-unfinished-learning/" target="_blank" class="text-secondary">unfinished learning</a>
        exacerbated by school closures. Students need personal, one-on-one support,
        both in the wake of the pandemic and beyond. Unfortunately teachers who are
        often stretched thin are not always able to deliver that level of attention
        at every moment, and a national teacher shortage in secondary math makes
        additional hiring a challenge.`,
      image: "icon-collaborate.svg",
    },
    {
      header: "Response: Online Math Tutoring",
      description: `Integrating online tutoring into your academic initiatives
        can expand the capacity of your teaching team and support student achievement.
        Yup's unlimited, personalized, 24/7 on-demand tutoring is
        like having a Teaching Assistant in every classroom to give students
        the help they need right when they need it. Regardless of the type
        or structure of your school, or the individual needs and learning
        styles of your students, Yup will work with you to ensure your school
        gets the most out of employing effective, evidence-based tutoring practices.`,
      image: "icon-enrichment.svg",
    },
  ],

  // How it works section
  howItWorksHeader: `How Yup Reinvents Online Math Tutoring for Schools`,
  howItWorksText: `While students can access private online tutoring sessions with Yup's
    <a href="${Routes.ourTutors}" target="_blank" class="text-secondary">expert tutors</a>
    outside of class, 24/7, Yup's Math Learning Support System can also be integrated
    into the school day. We collaborate with schools to develop effective tutoring
    programs shaped by administrators and teachers that meet the specific needs of
    your district, school, classrooms, and individual students. From High Dosage Tutoring
    and targeted test preparations, to around-the-clock instructional support and homework
    help, Yup is here to support you.`,
  howItWorksAction: "Learn More",
  howItWorksLink: Routes.highDosageTutoring,

  // Why it's effective
  effectiveHeader: "Why Online Math Tutoring Is Effective for Schools",
  effectiveText: [
    {
      icon: TrendingDownIcon,
      text: `<a href="https://www.usatoday.com/in-depth/news/education/2020/12/13/covid-online-school-tutoring-plan/6334907002/" target="_blank" class="text-secondary">Math achievement has dropped nationwide</a>,
        according to a report that examined scores from 4.4 million school students, and
        <a href="${Routes.blog}accelerating-to-address-unfinished-learning/" target="_blank" class="text-secondary">unfinished learning</a>
        has impacted students around the country.`,
    },
    {
      icon: AcademicCapIcon,
      text: `Dozens of studies demonstrate that tutoring accelerates student achievement across
        grade levels. With the right tutoring supports in place, students gain the equivalent of
        <a href="https://studentsupportaccelerator.com/research/to-date" target="_blank" class="text-secondary">3-15 months of extra classroom instructional time</a>.`,
    },
    {
      icon: ThumbUpIcon,
      text: `<a href="${Routes.blog}power-of-district-based-tutoring/" target="_blank" class="text-secondary">Districts around the country are turning to tutoring programs</a>
        as a proven method to boost student learning and success.`,
    },
  ],
  effectiveQuote: `Of all the academic interventions people have studied in-depth, tutoring has been shown to be the most effective.`,
  effectiveQuoteLink: "https://www.brown.edu/news/2021-03-04/accelerator",
  effectiveQuoteName: "Susanna Loeb",
  effectiveQuoteJob:
    "Professor of Education and Director of the Annenberg Institute for School Reform at Brown University",
  effectiveQuoteImg: Profiles.susanna,

  // Values section
  valuesHeader: "Turn to Yup for High-Quality, High-Dosage Tutoring",
  values: [
    {
      icon: AcademicCapIcon,
      header: "Math support built on effective, evidence-based practices",
      description: `Yup affords schools the ability to employ the most effective tutoring practices
        while supplementing classroom-based learning throughout the school day and when students are
        at home. From our NCTM aligned pedagogy, to our commitment towards establishing an HDT program
        that works for your school, Yup is here to support you.`,
      action: "How we teach",
      link: Routes.learningFundamentals,
      action2: "Our tutors",
      link2: Routes.ourTutors,
      image: "man-with-books-on-head.svg",
      imageAltText: "man-with-books-on-head.svg",
    },
    {
      icon: KeyIcon,
      header: "Data-driven insights at your fingertips",
      description: `Yup partner schools receive personalized dashboards showcasing student usage and
        progress metrics. All tutoring session transcripts  as well as tutor session summaries are made
        available so teachers and administrators can further identify student knowledge gaps and
        supplement their lessons accordingly.`,
      action: "How Yup works",
      link: Routes.features,
      image: "clipboard-lady.svg",
      imageAltText: "clipboard-lady.svg",
    },
    {
      icon: LightningBoltIcon,
      header: "Privacy and security, guaranteed",
      description: `Working with students means privacy and security is always a top priority. Yup guarantees
        compliance - and beyond - with Federal and State policies to ensure safe, fun and productive learning
        interactions.`,
      action: "Student privacy",
      link: Routes.privacy,
      image: "tutor-onboarding-ready.svg",
      imageAltText: "tutor-onboarding-ready.svg",
    },
  ],

  // FAQ section
  faqHeader: "Frequently Asked Questions",
  faqs: [
    {
      question: "What Is Online Math Tutoring for Schools?",
      answer: `Yup is an on-demand, online tutoring service that makes high-quality tutoring available
        to your students 24 hours a day, seven days a week. Whether during class, study hall, or at home,
        Yup makes personalized tutoring simple and a natural extension of the learning already happening
        in classrooms at your school.`,
      points: [
        {
          target: "For Students",
          text: `Yup means 24/7 and on-demand access to personalized, one-on-one online tutoring
            sessions with expert math tutors`,
        },
        {
          target: "For Teachers",
          text: `Yup is a flexible tool to support every student and get data-driven insights on
            student learning`,
        },
        {
          target: "For Administrators",
          text: `Yup's online tutoring service expands the capacity of your teaching team and helps
            you ensure equitable access to a supportive learning environment for all students`,
        },
      ],
      action: "School Day Integrations",
      link: Routes.highDosageTutoring,
    },
    {
      question: "What does onboarding Yup look like?",
      answer: `Onboarding teachers and students with Yup is easy. Once schools sign with Yup,
      they are paired with a dedicated Customer Success Manager who organizes a goal-setting
      conversation with the administrative team, hosts teacher onboarding and training,
      and provides continued support to ensure your goals are being met.`,
    },
  ],

  // Closing CTA
  closingCTA: "Learn what it means to bring Yup to your school or district",
  closingCTA2: "",
  closingCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule Demo",
    },
  ],
}

function QuotesSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/testimonials#component-54ee9cbce13afa3082f12b00ff1c00a6
  return (
    <div className="bg-secondary mb-16">
      <Carousel
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={10000}
        showThumbs={false}
        width="100vw"
      >
        {Content.quotes.map(quote => (
          <section key={quote.name} className="overflow-hidden h-full">
            <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
              <svg
                className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
                />
              </svg>

              <svg
                className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
                width={404}
                height={784}
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={784}
                  fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
                />
              </svg>

              <div className="relative lg:flex lg:items-center">
                <div className="hidden lg:block lg:flex-shrink-0">
                  <Img
                    className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                    src={quote.profile}
                    alt={quote.name}
                  />
                </div>

                <div className="relative lg:ml-10">
                  <svg
                    className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-secondary-200 opacity-50"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 144 144"
                    aria-hidden="true"
                  >
                    <path
                      strokeWidth={2}
                      d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                    />
                  </svg>
                  <blockquote className="relative">
                    <div className="text-2xl leading-9 font-medium text-white">
                      <p>{quote.text}</p>
                    </div>
                    <footer className="mt-8">
                      <div className="flex">
                        <div className="flex-shrink-0 lg:hidden">
                          <Img
                            className="h-12 w-12 rounded-full"
                            src={quote.profile}
                            alt={quote.name}
                          />
                        </div>
                        <div className="ml-4 lg:ml-0 text-left">
                          <div className="text-base font-medium text-white">
                            {quote.name}
                          </div>
                          <div className="text-base font-medium text-gray-100">
                            {quote.job1}
                          </div>
                          <div className="text-base font-medium text-gray-100">
                            {quote.job2}
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </section>
        ))}
      </Carousel>
    </div>
  )
}

function HowItWorksSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/testimonials#component-54ee9cbce13afa3082f12b00ff1c00a6
  return (
    <Card>
      <div className="flex flex-col text-center items-center">
        <div className="px-4 text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {Content.howItWorksHeader}
          </h2>
          <p
            className="mt-5 text-lg text-gray-500"
            dangerouslySetInnerHTML={{ __html: Content.howItWorksText }}
          />
        </div>
      </div>
      <HowItWorks showDescription={true} />
      <div className="mb-8 text-center">
        <SecondaryButton
          href={Content.howItWorksLink}
          text={Content.howItWorksAction}
        />
      </div>
    </Card>
  )
}

function EffectiveSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-64ac58e032276db96bf343a8d4f332a8
  return (
    <Card>
      <p className="my-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        {Content.effectiveHeader}
      </p>
      {Content.effectiveText.map(item => (
        <div key={item.text} className="flex py-4 items-center">
          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-secondary text-white">
            <item.icon className="h-6 w-6" aria-hidden="true" />
          </div>
          <dd
            className="ml-16 text-lg text-gray-500"
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      ))}
    </Card>
  )
}

function EffectiveQuoteSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/testimonials#component-fd7b8bd425f42f6504b22e1ecc6b43c9
  return (
    <div className="bg-secondary my-8">
      <div className="max-w-7xl mx-auto relative px-4 sm:px-6 lg:px-8">
        <section className="overflow-hidden h-full">
          <div className="relative pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
              />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
              />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <Img
                  className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src={Content.effectiveQuoteImg}
                  alt={Content.effectiveQuoteName}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-secondary-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-2xl leading-9 font-medium text-white text-center">
                    <a
                      className="underline"
                      href="https://www.brown.edu/news/2021-03-04/accelerator"
                      target="_blank"
                    >
                      {Content.effectiveQuote}
                    </a>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <Img
                          className="h-12 w-12 rounded-full"
                          src={Content.effectiveQuoteImg}
                          alt={Content.effectiveQuoteName}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0 text-left">
                        <div className="text-base font-medium text-white">
                          {Content.effectiveQuoteName}
                        </div>
                        <div className="text-base font-medium text-gray-100">
                          {Content.effectiveQuoteJob}
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

function FeaturesSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      <h2 className="text-3xl my-4 font-extrabold text-center tracking-tight text-gray-900 sm:text-4xl">
        {Content.valuesHeader}
      </h2>
      {Content.values.map((section, index) => (
        <div
          className={`flex flex-col ${
            index % 2 ? "md:flex-row" : "md:flex-row-reverse"
          } max-w-7xl mx-auto items-center`}
          key={`teaching-section-${index}`}
        >
          <div className="w-full p-4 flex-grow md:w-1/3">
            <Img
              src={section.image}
              alt={section.imageAltText}
              className="w-full h-full max-w-sm object-fit mx-auto"
            />
          </div>
          <div className="text-center md:text-left w-full py-12 md:w-2/3">
            <div className="md:ml-auto md:px-10">
              <p className="mt-2 text-gray-900 text-2xl font-extrabold tracking-tight sm:text-3xl">
                {section.header}
              </p>
              {section.description.split(/\n\n/g).map((description, i) => (
                <p
                  className="mt-3 text-lg text-gray-500"
                  key={`teaching-section-${index}-${i}`}
                >
                  {description}
                </p>
              ))}
              <div className="mt-8">
                <SecondaryButton href={section.link} text={section.action} />
                {section.action2 && section.link2 && (
                  <SecondaryButton
                    href={section.link2}
                    text={section.action2}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}

function ValuePropSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-cdca7eda27bdaceb45026dca4c17efe7
  return (
    <div className="mx-auto max-w-md text-center sm:max-w-3xl lg:max-w-7xl">
      <p className="mt-4 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        {Content.featuresHeader}
      </p>
      <div className="mt-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {Content.features.map(feature => (
            <div key={feature.feature} className="pb-12">
              <Card className="h-full">
                <div className="-mt-12">
                  <div>
                    <span className="inline-flex items-center justify-center p-3 bg-blue rounded-md shadow-lg">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                    {feature.feature}
                  </h3>
                  <p className="mt-5 text-base text-gray-500">{feature.text}</p>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function FAQSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/faq-sections#component-8699d80b13ef524eb573e54b4d4b89d1

  return (
    <Card>
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h1 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          {Content.faqHeader}
        </h1>
        <dl className="my-6 space-y-6 divide-y divide-gray-200">
          {Content.faqs.map(faq => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        {faq.question}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={`h-6 w-6 transform ${
                            open ? "-rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-500">{faq.answer}</p>
                    {faq.points && (
                      <ul className="text-base text-gray-500 list-disc px-8 py-4">
                        {faq.points.map(point => (
                          <li key={point.text}>
                            <span className="mr-2 font-bold">
                              {point.target}:
                            </span>
                            {point.text}
                          </li>
                        ))}
                      </ul>
                    )}
                    {faq.action && faq.link && (
                      <SecondaryButton href={faq.link} text={faq.action} />
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </Card>
  )
}

export default function AdministratorsPage() {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.administrators}
      />
      <PageHeader
        smallTitle={Content.subheader}
        mainTitle={Content.header}
        description={Content.description}
        video={Content.video}
        ctaLink={Content.headerCTALink}
        ctaText={Content.headerCTAText}
      />
      <QuotesSection />
      <ValuesList
        header={Content.howItHelpsHeader}
        values={Content.howItHelps}
      />
      <ValuePropSection />
      <HowItWorksSection />
      <EffectiveSection />
      <EffectiveQuoteSection />
      <FeaturesSection />
      <FAQSection />
      <ClosingCTA
        title={Content.closingCTA}
        title2={Content.closingCTA2}
        actions={Content.closingCTAActions}
      />
    </Layout>
  )
}
